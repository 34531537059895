const locale = {
	APPLICATIONS: 'מועצה דתית',
	USERS: 'משתמשים',
	LOGS: 'מעקב מערכת',
	CONFIGS: "הגדרות",
	ADMIN_AREA:"אזור מנהל",
	MENUS: "תפריטים",
	NEW_BUSINESS:"עסק חדש",
	MEDIA:"מנהל קבצים",
	PAGES:"דפים",
	CATEGORIES:"קטגוריות",
	PASSEDAWAY:'נפטרים',
	FORMS:'טפסים',
	BUSINESS:'עסקים',

};

export default locale;