import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectMainTheme } from 'app/store/ypadmin/settingsSlice';

const useEnhancedEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;

function YpTheme(props) {
	const direction = useSelector(({ ypadmin }) => ypadmin.settings.defaults.direction);
	const mainTheme = useSelector(selectMainTheme);


	useEnhancedEffect(() => {
		document.body.dir = direction;
	}, [direction]);

	// console.warn('YpTheme:: rendered',mainTheme);
	return (
            <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>
    );
}

export default React.memo(YpTheme);