import YpUtils from '@ypadmin/utils/YpUtils';
import axios from '../axios';
import store from './../store';
import { setstate } from 'app/store/ypadmin/timerSlice';
import { setBizSettings } from './store/bizSlice';

import authRoles from './authRoles';


class authService extends YpUtils.EventEmitter {
	
	
	init() {
		this.handleAuthentication();
	}
	
	handleAuthentication = () => {
		this.isAuthTokenValid()
		.then((user) => {
			this.emit('onAutoLogin', true);
		})
		.catch((error) => {
			this.emit('onAutoLogout');
		});
	};
	
	isAuthTokenValid() {

		return new Promise((resolve, reject) => {
			axios
				.get('users/info')
				.then(response => {
					if (response && response.status === 200 && response.data && response.data.id === 1) {
						store.dispatch(setstate(response.data.data.sessionExp));
						// this.getUserSettings();
						resolve(response.data.data);
					}
					else {
						reject();
					}

				})
				.catch((error) => {
					reject();
				});
		});
	}

	getUserSettings() {
		return new Promise((resolve, reject) => {
			axios
				.get('Settings')
				.then(response => {
					if (response.status === 200 && response.data && response.data.id === 1) {
						store.dispatch(setBizSettings(response.data.data));
					}
					else {
						resolve();
					}
				})
				.catch((error) => {
					resolve();
					//reject(error);
				});
		});
	}

	login = (user, password, captcha, rememberMe) => {
		return new Promise((resolve, reject) => {
			axios
				.post('users/login', {
					Usr: user,
					Pwd: password,
					Captcha: captcha,
					RememberMe: rememberMe
				})
				.then(response => {
					if (response.status === 200 && response.data && response.data.id === 1) {
						// response.data.data.roles!=='מנהל נפטרים'&&.getUserSettings();
						resolve(response.data);
					}
					else if (response.status === 200 && response.data && response.data.id < 1) {
						return resolve(response.data);
					}

					else {
						reject(response.data.error);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	logout = () => {
		return new Promise((resolve, reject) => {
			axios
				.post('users/logout')
				.then(response => {
					if (response.status === 200 && response.data && response.data.id === 1) {
						resolve();
					} else {
						reject(response.data.error);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};
}

const instance = new authService();

export default instance;