import YpSplashScreen from '@ypadmin/core/YpSplashScreen';
import authService from './authService';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from 'app/store/ypadmin/messageSlice';
import { setUserData, logoutUser } from './store/userSlice';


class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		return Promise.all([
			this.tokenCheck()
		]).then(() => {
			this.setState({ waitAuthCheck: false });
		});
	}

	tokenCheck = () =>
		new Promise(resolve => {
			authService.on('onAutoLogin', () => {
				//this.props.showMessage({ message: 'Logging in...' });

				/* Sign in and retrieve user data from Api */
				authService
					.isAuthTokenValid()
					.then((user) => {
						this.props.setUserData(user);
						resolve();
						//this.props.showMessage({ message: 'Logged in!' });
					})
					.catch((error) => {
						//this.props.showMessage({ message: error.message });
						resolve();
					});
			});

			authService.on('onAutoLogout', message => {
				if (message)
					this.props.showMessage({ message });

				this.props.logout();

				resolve();
			});

			// authService.on('onNoAccessToken', () => {
			// 	resolve();
			// });
			authService.init();


			return Promise.resolve();
		});

	render() {
		return this.state.waitAuthCheck ? <YpSplashScreen /> : <>{this.props.children}</>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			showMessage,
			hideMessage
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);