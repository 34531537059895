import React from 'react';
import i18Trans from './i18n.json';
import YpUtils from '@ypadmin/utils'

export const mainPageName = 'error404';

YpUtils.loadLangs(`${mainPageName}Page`, i18Trans);

const Error404PageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/errors/error404',
			component: React.lazy(() => import('./Error404Page'))
		}
	]
};

export default Error404PageConfig;
