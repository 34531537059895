import React, { useEffect } from 'react';
import { setFormsCount } from 'app/store/ypadmin/formsCountSlice';
import config from '../../../configs/settingsConfig';
import store from '../../../store';
import { useSelector } from 'react-redux';

const signalR = require("@microsoft/signalr");


const SignalRConnection = () => {
    const userRole = useSelector(({ auth }) => auth.user.roles);
    
    useEffect(() => {
        if (userRole !== 'מנהל מערכת' && userRole !== 'SuAdmin') return;
        try {
            let connection = new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.None)
                .withUrl(config.apiUrl + "formHub")
                .build();

            connection.on("formNotify", data => {
                store.dispatch(setFormsCount(data ?? 0));
            });
            connection.start()
        }
        catch (error) {
            console.log('SIGNAL R ERR');
        }
    }, []);

    return (
        <div></div>
    )
}

export default SignalRConnection;