import Provider from 'react-redux/es/components/Provider';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AppContext from './AppContext';
import routes from './configs/routesConfig';
import store from './store';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';
import i18n from 'i18n';
import 'moment/locale/he';


const withAppProviders = (Component) => (props) => {
  const WrapperComponent = () => (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <LocalizationProvider dateAdapter={DateAdapter} libInstance={moment} locale={i18n.language}>
        <Provider store={store}>
            <Component {...props} />
        </Provider>
      </LocalizationProvider>
    </AppContext.Provider>
  );

  return WrapperComponent;
};

export default withAppProviders;
