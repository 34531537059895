import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getResources } from 'app/configs/languages';

const resources = getResources();

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'he',
		fallbackLng: "he", // use he if detected lng is not available

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;