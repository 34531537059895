import YpScrollbars from '@ypadmin/core/YpScrollbars';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Hidden from '@mui/material/Hidden';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Logo from 'app/layouts/shared-components/Logo';
import NavbarFoldedToggleButton from 'app/layouts/shared-components/NavbarFoldedToggleButton';
import NavbarMobileToggleButton from 'app/layouts/shared-components/NavbarMobileToggleButton';
import Navigation from 'app/layouts/shared-components/Navigation';
import UserNavbarHeader from 'app/layouts/shared-components/UserNavbarHeader';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	content: {
		overflowX: 'hidden',
		overflowY: 'auto', '-webkit-overflow-scrolling': 'touch',
		background: 'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 40px, 100% 10px',
		backgroundAttachment: 'local, scroll',
		flexGrow: 1,
	},
	appBar: {flexDirection: 'row'},
	link: { 
		//background: 'none!important',
		color: `${theme.palette.mode === 'light' ? 
				`${theme.palette.secondary.contrastText}!important`
				 :
				`${theme.palette.primary.contrastText}!important`}`,
		'& .powered-by': {
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		}	
	},
}));

function NavbarLayout1(props) {
	const classes = useStyles();
	const theme = useTheme();
	const config = useSelector(({ ypadmin }) => ypadmin.settings.current.layout.config);

	return (
        <div className={clsx('flex flex-col overflow-hidden h-full', props.className)}>
			<AppBar
				color="primary"
				position="static"
				elevation={0}
				className={`${classes.appBar} flex flex-row items-center flex-shrink h-16 min-h-16 px-4`}
			>
				<div className="flex flex-1 mx-2">
					<Logo />
				</div>

				<Hidden lgDown>
					<NavbarFoldedToggleButton className="w-12 h-12 p-0" />
				</Hidden>

				<Hidden lgUp>
					<NavbarMobileToggleButton className="w-12 h-12 p-0">
						<Icon>{theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}"</Icon>
					</NavbarMobileToggleButton>
				</Hidden>
			</AppBar>

			<YpScrollbars className={clsx(classes.content)} option={{ suppressScrollX: true }}>
				{config.navbar.userProfile ? <UserNavbarHeader /> : null }
				<Navigation layout="vertical" />
			</YpScrollbars>

			<Link className={`${classes.link} w-full flex justify-center items-center p-2`}
					href="https://cdigital.co.il/" 
					target="_blank"
					>
					<span className="powered-by">Powered by Cdigital.co.il</span>
					<img className="mx-2 p-1"
						src="assets/imgs/logos/powered-by-logo.svg"
						alt="Powered by logo"
						width="32"
						/>
			</Link>
		</div>
    );
}

export default React.memo(NavbarLayout1);
