import React from 'react';
import { Redirect } from 'react-router-dom';
import YpUtils from '@ypadmin/utils';
import authRoles from '../auth/authRoles'
import Error404PageConfig from 'app/main/errors/404/Error404PageConfig';
import Error500PageConfig from 'app/main/errors/500/Error500PageConfig';
import MaintenancePageConfig from 'app/main/maintenance/MaintenancePageConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import UsersPageConfig from 'app/main/users/PageConfig';
import PassedAwayPageConfig from 'app/main/passedAway/PageConfig';
import FormsConfig from 'app/main/forms/PageConfig';
import HomePageConfig from 'app/main/home/PageConfig';
import LogsConfig from 'app/main/logs/PageConfig';
import PagesConfig from 'app/main/pages/PageConfig';
import MenusConfig from 'app/main/menus/PageConfig';
import MediaConfig from 'app/main/media/PageConfig';
import CatsConfig from 'app/main/categories/PageConfig';
import BusinessConfig from 'app/main/business/PageConfig';
// import MediaConfig from 'app/main/Components/MediaGallery/Gallery/PageConfig';



const passedAwayAdminConfigs = [
	Error404PageConfig,
	Error500PageConfig,
	PassedAwayPageConfig,
	HomePageConfig,
	LoginConfig
];
const userConfigs = [
	MaintenancePageConfig,
	MenusConfig,
	MediaConfig,
	PagesConfig,
	FormsConfig,
	CatsConfig,
	BusinessConfig
];


const adminRouteConfigs = [
	UsersPageConfig,
];

const suAdminRouteConfigs = [
	LogsConfig
];



const routes = [
	/* If you want to make whole app auth protected by default change defaultAuth for example:
	   The individual route configs which has auth option won't be overridden. */
	...YpUtils.generateRoutesFromConfigs(suAdminRouteConfigs, authRoles.suAdmin),
	...YpUtils.generateRoutesFromConfigs(adminRouteConfigs, authRoles.admin),
	...YpUtils.generateRoutesFromConfigs(userConfigs, authRoles.user),
	...YpUtils.generateRoutesFromConfigs(passedAwayAdminConfigs, authRoles.passedAwayAdmin),
	// ...YpUtils.generateRoutesFromConfigs(userConfigs, authRoles.ALLUser),

	{
		path: '/',
		exact: true,
		component: () => <Redirect to={`/homepage`} />
		// component: () => <Redirect to={`/pages`} />
	},

	{
		component: () => <Redirect to="/errors/error404" />
	}
];

export default routes;
