import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { useSelector } from 'react-redux';
import { webApiUrl } from "app/configs/settingsConfig";
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

const ShowMiniSite = () => {
	const bizSettings = useSelector(({ auth }) => auth.biz.bizSettings);
    const { t } = useTranslation();

    return (
        <Tooltip title={<span className='text-14'>{t('SHOW_SITE')}</span>}>
            <IconButton
                onClick={() => window?.open(webApiUrl)}
                size="large">
                <Icon className="cursor-pointer">
                    web
                </Icon>
            </IconButton>
        </Tooltip>
    );
}

export default ShowMiniSite;