import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';


const useStyles = makeStyles(theme => ({
	root: {
		padding: '0 7px',
		fontSize: 11,
		fontWeight: 600,
		height: 20,
		minWidth: 20,
		borderRadius: 20,
		display: 'flex',
		alignItems: 'center',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText
	}
}));

function YpNavBadge(props) {

	const classes = useStyles(props);
	const { className, badge } = props;
	// const count = useSelector(({ ypadmin }) => ypadmin['reqCount'].state);
	let count = useSelector(({ ypadmin }) => ypadmin[props.badge.title].state);

	const [prevCount, setPrevCount] = useState(null);

	const audio = new Audio('assets/audio/notification.mp3');
	const ifNotificationsOn = JSON.parse(localStorage.getItem('notifications')) ?? true;

	useEffect(() => {
		if (count > prevCount && prevCount !== null && ifNotificationsOn) {
			let playPromise = audio.play();

			if (playPromise !== undefined) {
				playPromise
					.then(_ => {
						// console.log("audio played auto");
					})
					.catch(error => {
						// console.log("playback prevented");
					});
			}
			//audio.play();
		}
		setPrevCount(count);
	}, [count]);

	return (
		count !== null && count > 0 && <div
			className={clsx(classes.root, className, 'item-badge')}
			style={{
				backgroundColor: badge.bg,
				color: badge.fg
			}}
		>
			{count}
		</div>

	);
}

YpNavBadge.propTypes = {
	badge: PropTypes.shape({
		title: PropTypes.node,
		bg: PropTypes.string,
		fg: PropTypes.string
	})
};
YpNavBadge.defaultProps = {};

export default withRouter(React.memo(YpNavBadge));