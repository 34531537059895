import i18next from 'i18next';
import en from './navigation-i18n/en';
import he from './navigation-i18n/he';
import authRoles from '../auth/authRoles'


i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('he', 'navigation', he);


const navigationConfig = [

	// {
	// 	id: 'all_tasks',
	// 	title: 'Dashboards',
	// 	translate: 'ADMINIST',
	// 	type: 'collapse',
	// 	// icon: 'list_alt',

	// 	//	auth: authRoles.technicianUser,
	// 	children: [
	// 		{
	// 			id: 'pages',
	// 			title: 'pages',
	// 			translate: 'PAGES',
	// 			type: 'item',
	// 			icon: 'web',
	// 			// auth: authRoles.admin,
	// 			url: '/pages'
	// 		},
	// 		{
	// 			id: 'menus',
	// 			title: 'menus',
	// 			translate: 'MENUS',
	// 			type: 'item',
	// 			icon: 'menu',
	// 			// auth: authRoles.admin,
	// 			url: '/menus'
	// 		},
	// 		{
	// 			id: 'media',
	// 			title: 'media',
	// 			translate: 'MEDIA',
	// 			type: 'item',
	// 			icon: 'perm_media',
	// 			// auth: authRoles.admin,
	// 			url: '/media'
	// 		},

	// 	]
	// },

	{
		id: 'pages',
		title: 'pages',
		translate: 'PAGES',
		type: 'item',
		icon: 'web',
		auth: authRoles.user,
		url: '/pages'
	},
	{
		id: 'categories',
		title: 'categories',
		translate: 'CATEGORIES',
		type: 'item',
		icon: 'apps',
		auth: authRoles.user,
		url: '/categories'
	},
	{
		id: 'menus',
		title: 'menus',
		translate: 'MENUS',
		type: 'item',
		icon: 'menu',
		auth: authRoles.user,
		url: '/menus'
	},
	{
		id: 'media',
		title: 'media',
		translate: 'MEDIA',
		type: 'item',
		icon: 'perm_media',
		auth: authRoles.user,
		url: '/media'
	},
	{
		id: 'passedAway',
		title: 'passedAway',
		translate: 'PASSEDAWAY',
		type: 'item',
		icon: 'import_contacts',
		auth: authRoles.passedAwayAdmin,
		url: '/passedAway'
	},
	{
		id: 'business',
		title: 'business',
		translate: 'BUSINESS',
		type: 'item',
		icon: 'business',
		auth: authRoles.user,
		url: '/business'
	},
	{
		id: 'forms',
		title: 'forms',
		translate: 'FORMS',
		type: 'item',
		icon: 'list_alt',
		badge: {
			title: 'formsCount',
			bg: 'red',
			fg: '#fff'
		},
		auth: authRoles.user,
		url: '/forms'
	},

	{
		id: 'adminArea',
		title: 'adminArea',
		translate: 'ADMIN_AREA',
		type: 'group',
		// icon: 'list_alt',
		auth: authRoles.admin,
		children: [
			{
				id: 'Users',
				title: 'Users',
				translate: 'USERS',
				type: 'item',
				icon: 'person',
				// auth: authRoles.admin,
				url: '/Users'
			},

			{
				id: 'Logs',
				title: 'Logs',
				translate: 'LOGS',
				type: 'item',
				icon: 'bug_report_icon',
				auth: authRoles.suAdmin,
				url: '/Logs'
			},
		]
	},
];

export default navigationConfig;