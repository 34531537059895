import { createSlice } from '@reduxjs/toolkit';


let initialState = {
	state: null,
	data: null
};

const formsCount = createSlice({
	name: 'formsCount',
	initialState,
	reducers: {
		// getFormsCount: (state, action) => {
		// 	return action.payload;
		// },

		setFormsCount: (state, action) => {
			let count = 0;
			for (let i = 0; i < action.payload.length; i++) {
				const element = action.payload[i];
				count = count + element?.notifCount;
			}

			state.state = count;
			state.data = action.payload;
		},
	}
});

export const { setFormsCount } = formsCount.actions;

export default formsCount.reducer;