// import YpSearch from '@ypadmin/core/YpSearch';
// import Shortcuts from '@ypadmin/core/Shortcuts';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import { ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import NavbarMobileToggleButton from 'app/layouts/shared-components/NavbarMobileToggleButton';
// import QuickPanelToggleButton from 'app/layouts/shared-components/quickPanel/QuickPanelToggleButton';
import UserMenu from 'app/layouts/shared-components/UserMenu';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/ypadmin/settingsSlice';
import Timer from './Timer';
import FullScreen from './FullScreen';
import SignalRConnection from './SignalRConnection';
// import CurrBiz from './CurrBiz';
import ShowSite from "./ShowSite.js";

// import LanguageSwitcher from '../../shared-components/LanguageSwitcher';

const useStyles = makeStyles(theme => ({
    root: {}
}));



function ToolbarLayout1(props) {
    const config = useSelector(({ ypadmin }) => ypadmin.settings.current.layout.config);
    const toolbarTheme = useSelector(selectToolbarTheme);
    const classes = useStyles(props);


    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar
                id="ypadmin-toolbar"
                className={clsx(classes.root, 'flex relative z-10 h-16')}
                color="default"
                style={{ backgroundColor: toolbarTheme.palette.background.paper }}
                elevation={2}
            >
                <Toolbar className="p-0">
                    {config.navbar.display && config.navbar.position === 'left' && (
                        <Hidden lgUp>
                            <NavbarMobileToggleButton className="w-12 h-12 p-0" />
                        </Hidden>
                    )}

                    <div className="flex flex-1">
                        {/* <Hidden mdDown>
                                <YpShortcuts className="px-16" />
                            </Hidden> */}
                    </div>

                    <div className="flex items-center px-6">
                        {/* <LanguageSwitcher /> */}
                        {/* <YpSearch /> */}
                        {/* <QuickPanelToggleButton /> */}
                        <ShowSite />
                        <FullScreen />
                        <Timer />
                        {/* <CurrBiz /> */}
                        <SignalRConnection />
                        <UserMenu />
                    </div>

                    {config.navbar.display && config.navbar.position === 'right' && (
                        <Hidden lgUp>
                            <NavbarMobileToggleButton />
                        </Hidden>
                    )}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default React.memo(ToolbarLayout1);
