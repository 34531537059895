import React from 'react';
import i18Trans from './i18n.json';
import YpUtils from '@ypadmin/utils'
import authRoles from './../../auth/authRoles';

export const mainPageName = 'menus';
export const reduxSliceName = mainPageName;
export const apiEndPoint = mainPageName;

YpUtils.loadLangs(`${mainPageName}Page`, i18Trans);

const PageConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path:`/${mainPageName}/:id`,
			//	auth:authRoles.admin,
			component: React.lazy(() => import(`./PageDetails`))
		},
		// {
		// 	path:`/${mainPageName}/profile`,
		// 	//exact:true,
		// 	//auth:authRoles.ALLUser,
		// 	component: React.lazy(() => import(`./PageDetails`))
		// },
		{
			path:`/${mainPageName}`,
			exact:true,
			auth:authRoles.admin,
			component: React.lazy(() => import('./Index'))
		},		
	]
};

export default PageConfig;