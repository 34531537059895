import React from 'react';
import i18Trans from './i18n.json';
import YpUtils from '@ypadmin/utils'

export const mainPageName = 'media';
export const reduxSliceName = mainPageName;
export const apiEndPoint = mainPageName;

// YpUtils.loadLangs(`${mainPageName}Page`, i18Trans);

const PageConfig = {
	settings: {
		layout: {}
	},
	routes: [
		{
			path: `/${mainPageName}`,
			exact: true,
			component: React.lazy(() => import('./Index'))
		},
	]
};

export default PageConfig;