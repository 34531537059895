import React from 'react';
import i18Trans from './i18n.json';
import YpUtils from '@ypadmin/utils'

export const mainPageName = 'maintenance';

YpUtils.loadLangs(`${mainPageName}Page`, i18Trans);

const MaintenancePageConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/maintenance',
			component: React.lazy(() => import('./MaintenancePage'))
		}
	]
};

export default MaintenancePageConfig;
