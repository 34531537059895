import React, { useState} from 'react';
import IconButton from '@mui/material/IconButton';

import Icon from '@mui/material/Icon';


const FullScreen = () => {
    const [full, setFull] = useState(false);
    let doc = window.document;
    let docEl = doc.documentElement;
    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;


    function toggleFullScreen() {
        if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            requestFullScreen.call(docEl);
            setFull(true);
        }
        else {
            cancelFullScreen.call(doc);
            setFull(false);
        }
    }

    return (
        <IconButton className="w-12 h-12" onClick={toggleFullScreen} size="large">
            <Icon className="cursor-pointer">
                {full ? 'fullscreen_exit' : 'fullscreen'}
            </Icon>
        </IconButton>
    );
}

export default FullScreen;