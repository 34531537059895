import AppBar from '@mui/material/AppBar';
import { ThemeProvider} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from 'app/store/ypadmin/settingsSlice';

function FooterLayout1(props) {
	const footerTheme = useSelector(selectFooterTheme);

	return (
            <ThemeProvider theme={footerTheme}>
                <AppBar id="ypadmin-footer" className="relative z-10" color="default">
                    <Toolbar className="px-16 py-0 flex items-center">
                        <Typography>Footer</Typography>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
    );
}

export default React.memo(FooterLayout1);
