import { YpDialogProvider } from '@ypadmin/core/YpDialog';
import YpMessage from '@ypadmin/core/YpMessage';
import YpScrollbars from '@ypadmin/core/YpScrollbars';
import YpSuspense from '@ypadmin/core/YpSuspense';
import makeStyles from '@mui/styles/makeStyles';
import AppContext from 'app/AppContext';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
		'&.scroll-content': {
			'& $wrapper': {},
			'& $contentWrapper': {},
			'& $content': {}
		},
		'& .navigation': {
			'& .list-subheader-text, & .list-item-text, & .item-badge, & .arrow-icon': {
				transition: theme.transitions.create('opacity', {
					duration: theme.transitions.duration.shortest,
					easing: theme.transitions.easing.easeInOut
				})
			}
		}
	},
	wrapper: {
		display: 'flex',
		position: 'relative',
		width: '100%',
		height: '100%',
		flex: '1 1 auto'
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		zIndex: 3,
		overflow: 'hidden',
		flex: '1 1 auto'
	},
	content: {
		position: 'relative',
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 2
	}
}));

function Layout1(props) {
	const config = useSelector(({ ypadmin }) => ypadmin.settings.current.layout.config);

	const appContext = useContext(AppContext);
	const classes = useStyles(props);
	const { routes } = appContext;

	return (
		<div id="ypadmin-layout" className={clsx(classes.root, 'fullwidth', `scroll-content`)}>
			{config.leftSidePanel.display && <LeftSideLayout1 />}

			<div className="flex flex-1 flex-col overflow-hidden relative">
				{config.toolbar.display && config.toolbar.position === 'above' && <ToolbarLayout1 />}

				<div className={classes.wrapper}>
					{config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

					<div className={classes.contentWrapper}>
						{config.toolbar.display &&
							config.toolbar.position === 'below' &&
							config.toolbar.style === 'fixed' && <ToolbarLayout1 />}

						<YpScrollbars className={classes.content} scrollToTopOnRouteChange>
							{config.toolbar.display &&
								config.toolbar.position === 'below' &&
								config.toolbar.style !== 'fixed' && <ToolbarLayout1 />}

							<YpDialogProvider />

							<YpSuspense>{renderRoutes(routes)}</YpSuspense>

							{props.children}

							{config.footer.display &&
								config.footer.position === 'below' &&
								config.footer.style !== 'fixed' && <FooterLayout1 />}
						</YpScrollbars>

						{config.footer.display &&
							config.footer.position === 'below' &&
							config.footer.style === 'fixed' && <FooterLayout1 />}
					</div>

					{config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
				</div>

				{config.footer.display && config.footer.position === 'above' && <FooterLayout1 />}
			</div>

			{config.rightSidePanel.display && <RightSideLayout1 />}

			<YpMessage />
		</div>
	);
}

export default React.memo(Layout1);