import YpAuthorization from '@ypadmin/core/YpAuthorization';
import YpLayout from '@ypadmin/core/YpLayout';
import YpTheme from '@ypadmin/core/YpTheme';
import { YpDialogProvider } from '@ypadmin/core/YpDialog';
import history from '@history';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Auth } from './auth';
// import "moment/locale/he";
import { useSelector } from 'react-redux';
import { selectCurrLangDir } from 'app/store/i18nSlice';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import withAppProviders from './withAppProviders';
import rtlPlugin from 'stylis-plugin-rtl';


const emotionCacheOptions = {
	rtl: {
		key: 'muirtl',
		stylisPlugins: [rtlPlugin],
		prepend: true,
	},
	ltr: {
		key: 'muiltr',
		stylisPlugins: [],
		prepend: true,
	},
};



const App = () => {
	// const langDirection = useSelector(selectCurrLangDir);

	return (
		<CacheProvider value={createCache(emotionCacheOptions['rtl'])}>
			<GoogleReCaptchaProvider reCaptchaKey="6Lc523obAAAAADh7_ipY7MXkDkAx-rTBU7lT64nF">
				<Auth>
					<Router history={history}>
						<YpAuthorization>
							<YpTheme>
								<YpDialogProvider>
									<YpLayout />
								</YpDialogProvider>
							</YpTheme>
						</YpAuthorization>
					</Router>
				</Auth>
			</GoogleReCaptchaProvider>
		</CacheProvider>
	);
};

export default withAppProviders(App)();