import { combineReducers } from '@reduxjs/toolkit';
import auth from '../auth/store';
import login from '../main/login/store';
import ypadmin from './ypadmin';
import i18n from './i18nSlice';


const createReducer = asyncReducers =>
	combineReducers({
		auth,
		ypadmin,
		login,
		i18n,
		...asyncReducers
	});

export default createReducer;
