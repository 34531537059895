import { combineReducers } from '@reduxjs/toolkit';
import message from './messageSlice';
import navbar from './navbarSlice';
import settings from './settingsSlice';
import navigation from './navigationSlice';
import timer from './timerSlice';
import formsCount from './formsCountSlice';

const reducers = combineReducers({
	settings,
	navbar,
	navigation,
	message,
	timer,
	formsCount,
});


export default reducers;