import { createSlice } from '@reduxjs/toolkit';
import authService from '../authService';
import { setUserData } from './userSlice';
import { setstate } from 'app/store/ypadmin/timerSlice';



export const submitLogin = ({ user, password, captcha, rememberMe }) => async dispatch => {

	return authService
		.login(user, password, captcha, rememberMe)
		.then((res) => {
			if (res.id === 1) {
				dispatch(setstate(res.data.sessionExp));
				dispatch(setUserData(res.data));
				return dispatch(loginSuccess());
			}
			else {
				return res;
			}
		})


		.catch((error) => {
			return dispatch(loginError(error.response.status));
		});
};



const initialState = {
	success: false,
	error: {
		username: null,
		password: null
	}
};

const loginSlice = createSlice({
	name: 'auth/login',
	initialState,
	reducers: {
		loginSuccess: (state, action) => {
			state.success = true;
		},
		loginError: (state, action) => {
			state.success = false;
			state.error = action.payload;
		}
	},
	extraReducers: {}
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;