import { createSlice} from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/ypadmin/settingsSlice';

// import { resetNavigation } from 'app/store/ypadmin/navigationSlice';
import { showMessage } from 'app/store/ypadmin/messageSlice';
import authService from '../authService';


export const setUserData = user => async (dispatch, getState) => {
	/* Set User Settings */
	if (user && user.settings) {
	//  history.push('/homepage');

		dispatch(setDefaultSettings(user.settings));
		dispatch(setUser(user));
	}
};

export const updateUserSettings = settings => async (dispatch, getState) => {
	const oldUser = getState().auth.user;
	const user = _.merge({}, oldUser, { data: { settings } });

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
	const { user } = getState().auth;
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts
		}
	};

	dispatch(updateUserData(user));

	return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
	const { user } = getState().auth;

	if (!user.roles || user.roles.length === 0) {
		// is guest
		return null;
	}

	authService.logout()
		.then(response => {
			dispatch(setInitialSettings());
			dispatch(userLoggedOut());
			// dispatch(resetNavigation());
			history.push('/login');
		})
		.catch(err => {
			dispatch(showMessage({ message: "LOGOUT_ERR" }));
			// console.log(err);
		});

};

export const updateUserData = user => async (dispatch, getState) => {
	if (!user.roles || user.roles.length === 0) {
		// is guest
		return;
	}

	authService
		.updateUserData(user)
		.then(() => {
			dispatch(showMessage({ message: 'User data saved with api' }));
		})
		.catch(error => {
			dispatch(showMessage({ message: error.message }));
		});
};


export const initialState = {
	roles: [], // guest
	data: {

		// displayName: 'John Doe',
		// photoURL: 'assets/imgs/avatars/Velazquez.jpg',
		// email: 'johndoe@withinpixels.com',
		// shortcuts: ['calendar', 'mail', 'contacts', 'todo']
	},
};

export let roles = "";


const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			roles = action.payload.roles;
			return action.payload
		},
		userLoggedOut: (state, action) => initialState,

	},
	extraReducers: {}
});

export const { setUser, userLoggedOut} = userSlice.actions;


export default userSlice.reducer;
