const locale = {
	APPLICATIONS: 'Examples',
	// BUSINESS: 'Business',
	// CATEGORIES: 'Categories',
	// CONSULTANTS: 'Consultants',
	// LECTURES: 'Lectures',
	// BIDS: 'Bids',
	USERS: 'Users',
	// CONTACTS: 'Contact',
	// LECTURES_PARTICIPANTS: 'Participants',
	// REPORTS: 'Reports',
	// SETTINGS: 'Settings',
	LOGS:'Logs',
	TASKS: 'Tasks'
};

export default locale;
