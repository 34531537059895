import React from 'react';
import QuickPanel from 'app/layouts/shared-components/quickPanel/QuickPanel';

function RightSideLayout1(props) {
	return (
		<>
			<QuickPanel />
		</>
	);
}

export default React.memo(RightSideLayout1);
