import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'app/axios';

export const resetPwd = createAsyncThunk(`Users/resetPwdLink`, async (params) => {
	const response = await axios.post(`/Users/ResetPwdLink`,params);
	return response.data;
});

export const resetPwdWithToken = createAsyncThunk(`Users/resetPwdWithToken`, async params => {
	const response = await axios.post(`/Users/PwdWithToken`, params);
	return response.data;
});

const sliceLogin = createSlice({
	name: 'Users',
	initialState: null,
	reducers: {
		newItem: {
			reducer: (state, action) => action.payload,
			prepare: event => ({
				payload: {}
			})
		},
	},
	extraReducers: {
		[resetPwd.fulfilled]: (state, action) => action.payload.data,
		[resetPwdWithToken.fulfilled]: (state, action) => action.payload.data,
	//	[update.fulfilled]: (state, action) => action.payload.data,
	//	[add.fulfilled]: (state, action) => action.payload.data,
	//	[catsList.fulfilled]: (state, action) => ({
		// 	...state,
		// 	id: 'new',
		// })
	}
});

export const { newItem } = sliceLogin.actions;

export default sliceLogin.reducer;