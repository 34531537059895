
// Global admin languages - Add or remove languages from the list below
export function languages() {
	var langs = [];
	langs.push(require('./resources/en.json'));
	langs.push(require('./resources/he.json'));
	return langs;
};

export function getResources() {
	
	var langs = languages();
	var res = {};
	langs.forEach(lang => {
		res[[lang.conf.code]] = {translation : lang.translation};
	})

	return res;
}