/* Authorization Roles */

const suAdmin = 'SuAdmin';
const user = 'עסק';
const admin = 'מנהל מערכת';
export const passedAwayAdmin = 'מנהל נפטרים';
// const technician = 'טכנאי';
// const ticketsIssuer = 'מנפיק כרטיסי טיסה';
// const ticketsIssuerAndTechnician = 'טכנאי ומנפיק כרטיסי טיסה';

const authRoles = {
	suAdmin: [suAdmin],
	admin: [admin,suAdmin],
	user: [user,admin,suAdmin],
	passedAwayAdmin: [passedAwayAdmin,admin,suAdmin, user],
	// ALLUser: [suAdmin, admin, ticketsIssuer, technician, ticketsIssuerAndTechnician],
	onlyGuest: []
};

export default authRoles;