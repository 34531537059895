import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Slide from '@mui/material/Slide';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';

const DialogContext = React.createContext([]);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const useDialog = () => React.useContext(DialogContext);

function DialogContainer(props) {
	//const theme = useTheme();
	//const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const { t } = useTranslation();
	const { children, options, open, onClose, onKill } = props;

	return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={(event, reason) => {
				if (options.modal && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
					return;
				}
				else {
					onClose(event, reason);
				}
			}}
            classes={{
				paper: 'rounded-lg'
			}}
            TransitionProps={{
                onExited: onKill
            }}>
			{children ? children
				:
				<div style={{ minWidth: 250 }}>
					{options.title ? <DialogTitle id="alert-dialog-title" className='flex justify-center'>{options.title}</DialogTitle> : null}
					<DialogContent className='flex justify-center'>
						{options.description ?
							<DialogContentText id="alert-dialog-description" className="font-semibold" color="primary">
								{options.description}
							</DialogContentText>
							: null}
					</DialogContent>
					<DialogActions className='flex justify-center mb-2'>
						{options.showOkBtn ?
							<Button
								className='px-8'
								color="primary"
								autoFocus
								onClick={() => {
									options.okBtnClick && options.okBtnClick();
									onClose()
								}}
							>
								{t('translation:OK')}
							</Button>
							: null}
						{options.showYesBtn == null || options.showYesBtn ?
							<Button color="inherit"
								className='px-8'
								color="primary"
								style={options.yesBtnProps?.style}
								variant={options.yesBtnProps?.variant ?? 'text'}
								onClick={() => {
									options.yesBtnClick && options.yesBtnClick();
									onClose()
								}}>

								{options.yesBtnProps?.label ?? t('translation:YES')}
							</Button>
							: null}
						{options.showNoBtn == null || options.showNoBtn ?
							<Button
								className='px-8'
								color="primary"
								autoFocus
								variant={options.noBtnProps?.variant ?? 'text'}
								onClick={() => {
									options.noBtnClick && options.noBtnClick();
									onClose()
								}}
							>
								{options.noBtnProps?.label ?? t('translation:NO')}
							</Button>
							: null}
					</DialogActions>
				</div>
			}
		</Dialog>
    );
}

export const YpDialogProvider = ({ children }) => {

	const [dialogs, setDialogs] = React.useState([]);

	const createDialog = (option) => {
		const dialog = { ...option, open: true };
		setDialogs((dialogs) => [...dialogs, dialog]);
	};

	const closeDialog = () => {
		setDialogs((dialogs) => {
			const latestDialog = dialogs.pop();
			if (!latestDialog) return dialogs;
			if (latestDialog.onClose) latestDialog.onClose();
			return [...dialogs].concat({ ...latestDialog, open: false });
		});
	};
	const contextValue = React.useRef([createDialog, closeDialog]);

	return (
		<DialogContext.Provider value={contextValue.current}>
			{children}
			{dialogs.map((dialog, i) => {
				const { onClose, ...dialogParams } = dialog;
				const handleKill = () => {
					if (dialog.onExited) dialog.onExited();
					setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
				};

				return (
					<DialogContainer
						key={i}
						onClose={closeDialog}
						onKill={handleKill}
						{...dialogParams}
					/>
				);
			})}
		</DialogContext.Provider>
	);
}