import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'app/axios';


export const updateCurrBiz = createAsyncThunk(`Settings/update`, async (params) => {
	const response = await axios.put(`/Settings/updCurrBiz/${params}`, params);
	return await response.data;
});
export const getUserSettings = createAsyncThunk(`Settings/update`, async ({ dispatch }) => {
	// await axios.get(`/Settings`)
	// 	.then(response => {
	// 		if (response.status === 200 && response.data && response.data.id === 1) {
	// 			dispatch(setBizSettings(response.data.data));
	// 			// resolve(response.data.data);
	// 		}
	// 	})

	// return await response.data;
});


export const initialState = {
	bizSettings: {
		currBizData: {},
		currBiz: 4,
		currLang: 1,
		bizs: null,
		langs: [{ id: 1, langName: "עברית", code: "he-il", isRtl: true, imgUrl: "images/flag/he.png", isEnabled: true },
		{ id: 2, langName: "English", code: "en-us", isRtl: false, imgUrl: "images/flag/en.png", isEnabled: true },
		{ id: 3, langName: "Français", code: "fr-fr", isRtl: false, imgUrl: "images/flag/fr.png", isEnabled: true },
		{ id: 4, langName: "русский", code: "ru-ru", isRtl: false, imgUrl: "images/flag/ru.png", isEnabled: true },
		{ id: 5, langName: "عربى", code: "ar-eg", isRtl: true, imgUrl: "images/flag/eg.png", isEnabled: true }]
	}
};

export let roles = "";


const userSlice = createSlice({
	name: 'auth/biz',
	initialState,
	reducers: {
		setLangs: (state, action) => {
			state.bizSettings.langs = action.payload;
		},
		setBizSettings: (state, action) => {
			state.bizSettings.langs = action.payload.currBizLangs;
			state.bizSettings.currBiz = action.payload.currBizId;
			state.bizSettings.currLang = action.payload.currLangId;
			state.bizSettings.bizs = action.payload.bizs;
			state.bizSettings.currBizData = action.payload.currBiz;
		},
	},
	extraReducers: {}
});

export const { setUser, userLoggedOut, setLangs, setBizSettings} = userSlice.actions;

export default userSlice.reducer;
