
export const webApiUrl = 'https://datiteilat.co.il/'


const settingsConfig = {
	// baseApiUrl:
	// apiUrl: 'https://api.datiteilat.co.il/',
	apiUrl: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'https://192.168.1.15:45468/' : 'https://api.datiteilat.co.il/',

	webFileUrl: "https://api.datiteilat.co.il/",
	imgApiUrl: "https://api.datiteilat.co.il/",

	reCaptchaKey: '6LcpmcUaAAAAALQ46VZYDaGdIPZSq7IP0NzzrF3a',
	layout: {
		style: 'layout1', // layout1 layout2
		config: {} // checkout default layout configs at app/layouts for example  app/layouts/layout1/Layout1Config.js
	},
	customScrollbars: true,
	animations: true,
	direction: 'rtl',
	theme: {
		main: 'default',
		navbar: 'default',
		toolbar: 'default',
		footer: 'default'
	}
};

export default settingsConfig;