import { createSlice } from '@reduxjs/toolkit';


let initialState = {
	state: 14400
};

const timer = createSlice({
	name: 'timer',
	initialState,
	reducers: {
		gettimer: (state = 0, action) => {
			return action.payload;
		},
		reset: (state, action) => {
			state.state = initialState;
		},
		settimer: (state, action) => {
			state.state = action.payload;
		},

		setstate: (state, action) => {
			initialState = action.payload-10;
			state.state = initialState;
		},
	}
});


export const { gettimer, reset, settimer, setstate } = timer.actions;

export default timer.reducer;