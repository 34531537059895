import YpLoading from '@ypadmin/core/YpLoading';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */ 
function YpSuspense(props) {
	return <React.Suspense fallback={<YpLoading {...props.loadingProps} />}>{props.children}</React.Suspense>;
}

YpSuspense.propTypes = {
	loadingProps: PropTypes.object
};

YpSuspense.defaultProps = {
	loadingProps: {
		delay: 0
	}
};

export default YpSuspense;
