import React, { useEffect, useState } from 'react';
import { settimer } from 'app/store/ypadmin/timerSlice';
import { useSelector, useDispatch } from 'react-redux';
import authService from 'app/auth/authService';
import { setInitialSettings } from 'app/store/ypadmin/settingsSlice';
import { showMessage } from 'app/store/ypadmin/messageSlice';
import { useTranslation } from 'react-i18next';
import history from '@history';
import { createSlice } from '@reduxjs/toolkit';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';


const Timer = (props) => {
  const dispatch = useDispatch();
  const time = useSelector(({ ypadmin }) => ypadmin.timer.state);
  const [openDialog, setOpenDialog] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (time === 90) {
      setOpenDialog(true)
    }

    if (time <= 0) {
      authService.logout()
        .then(response => {
          dispatch(setInitialSettings());
          dispatch(userLoggedOut());
          history.push('/login');
        })
        .catch(err => {
          dispatch(showMessage({ message: "LOGOUT_ERR" }));
        });
    }
    const id = setTimeout(() => {
      dispatch(settimer(time - 1))
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, [time]);

  return (
    <div>
      <Dialog
        open={openDialog}
        // TransitionComponent={ <Slide direction="up" ref={ref} {...props} />}
        onClose={() => setOpenDialog(false)}
        disableEscapeKeyDown
        classes={{
          paper: 'rounded-8'
        }}

      >
        <>
          <DialogTitle id="alert-dialog-title">
            <p> {t('translation:LOGOUT_DIALOG_TITLE')}
              <span className='text-red-400 mx-4'>{time}</span>{t('translation:SECONDS')}</p>
          </DialogTitle>
          <DialogContent>
            <DialogContentText className='text-lg'>
              {t('translation:LOGOUT_DIALOG_DESC')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button color="primary"
              onClick={() => {
                authService.isAuthTokenValid()
                setOpenDialog(false)
              }}
            >
              {t('translation:YES')}
            </Button>

            <Button color="primary"
              onClick={() => {
                authService.logout()
                  .then(response => {
                    dispatch(setInitialSettings());
                    dispatch(userLoggedOut());
                    history.push('/login');
                  })
                  .catch(err => {
                    dispatch(showMessage({ message: "LOGOUT_ERR" }));
                    console.log(err);
                  });
                setOpenDialog(false)
              }
              }
            >
              {t('translation:NO')}
            </Button>
          </DialogActions>
        </>
      </Dialog>


      {/* {time} */}
    </div >)
};

const initialState = {
  roles: [],
  data: {}
};


const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState
  },
  extraReducers: {}
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default Timer;