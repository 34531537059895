import _ from '@lodash';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultSettings } from 'app/store/ypadmin/settingsSlice';

function NavbarFoldedToggleButton(props) {
	const dispatch = useDispatch();
	const settings = useSelector(({ ypadmin }) => ypadmin.settings.current);

	return (
        <IconButton
            className={props.className}
            onClick={() => {
				dispatch(
					setDefaultSettings(_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded))
				);
			}}
            color="inherit"
            size="large">
			{props.children}
		</IconButton>
    );
}

NavbarFoldedToggleButton.defaultProps = {
	children: <Icon>menu</Icon>
};

export default NavbarFoldedToggleButton;
