import React from 'react';
import { authRoles } from 'app/auth';
import i18Trans from './i18n.json';
import YpUtils from '@ypadmin/utils'

YpUtils.loadLangs('loginPage', i18Trans);

const LoginConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [

		{
			path: '/login/forgot-password',
			exact:true,
			component: React.lazy(() => import('./ForgotPassword'))
			//component: React.lazy(() => import('./Login'))
		},
		{
			path: '/login/reset-password/:email/:token',
			component: React.lazy(() => import('./ResetPassword'))
			//component: React.lazy(() => import('./Login'))
		},
		{
			path: '/login',
			exact: true,
			component: React.lazy(() => import('./Login'))
		},


	]
};

export default LoginConfig;
