import history from '@history';
import axios from 'axios';
import settingsConfig from 'app/configs/settingsConfig';
import { showMessage } from 'app/store/ypadmin/messageSlice';
import store from './store';
import { reset } from 'app/store/ypadmin/timerSlice';


const instance = axios.create({
  baseURL: `${settingsConfig.apiUrl}api/bo/v1/`
});



instance.defaults.withCredentials = true;
instance.interceptors.response.use(
  response => {
    store.dispatch(reset());
    return response;
  },
  err => {
    if ((window.location.pathname !== '/login' && !window.location.pathname.includes('/login/forgot-password') && !window.location.pathname.includes('/login/reset-password')) &&
      (err.response.status === 401 || err.response.status === 403 || err.response.status === 405)) {
      window.location.href = '/login';
    }

    if (err.response.status === 404) {
      history.push('/errors/error404');
    }
    else if (err.response.status === 401) {
      // Trow error again (may be needed for some other catch)
      return Promise.reject(err);
    }
    else if (err.response.status === 500) {
      store.dispatch(showMessage({ message: 'ERROR' }));
    }

    else {
      if (err.response?.data?.id < 0) {
        return err.response;
      }
      else {
        store.dispatch(showMessage({ message: 'ERROR' }));
        return err.response;
      }
    }
  }
);

//instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

export default instance;