import YpScrollbars from '@ypadmin/core/YpScrollbars';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import withReducer from 'app/store/withReducer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleQuickPanel } from './store/stateSlice';
import reducer from './store';

const useStyles = makeStyles(theme => ({
	root: {
		width: 280
	}
}));

function QuickPanel(props) {
	const dispatch = useDispatch();
	const state = useSelector(({ quickPanel }) => quickPanel.state);

	const classes = useStyles();

	return (
		<Drawer
			classes={{ paper: classes.root }}
			open={state}
			anchor="right"
			onClose={ev => dispatch(toggleQuickPanel())}
		>
			<YpScrollbars>
				<Typography>Quick Panel</Typography>
			</YpScrollbars>
		</Drawer>
	);
}

export default withReducer('quickPanel', reducer)(React.memo(QuickPanel));
